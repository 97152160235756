<template>
  <div
    :class="
      paginationStyle
        ? 'pagination-container pos_page new-pagination-style'
        : 'pagination-container pos_page'
    "
  >
    <el-pagination
      :background="background"
      :current-page.sync="currPage"
      :page-size="pagesSize"
      :layout="paginationStyle ? 'total, sizes, prev, pager, next' : layout"
      :page-sizes="pageSizes"
      :total="total"
      v-bind="$attrs"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
// import { scrollTo } from '@/utils/scroll-to'

export default {
  inject: {
    paginationStyle: {
      default: () => true,
    },
  },
  name: 'Pagination',
  props: {
    total: {
      required: true,
      type: Number,
    },
    currentPage: {
      type: Number,
      default: 0,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 40, 50]
      },
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper',
    },
    background: {
      type: Boolean,
      default: true,
    },
    autoScroll: {
      type: Boolean,
      default: true,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    handleSizeChange: Function,
    handleCurrentChange: Function,
  },
  data() {
    return {
      pageData: {
        pageSize: this.pageSize,
        currentPage: this.currentPage,
      },
      // currentPage:this.currentPage
    }
  },
  created() {},
  computed: {
    currPage: {
      get() {
        return this.currentPage
      },
      set(val) {
        this.$emit('update:currentPage', val)
      },
    },
    pagesSize: {
      get() {
        return this.pageSize
      },
      set(val) {
        this.$emit('update:pageSize', val)
      },
    },
  },
  methods: {
    // handleSizeChange(val) {
    //   console.log(`每页 ${val} 条`);
    //   this.pageSize=val
    //   this.$emit("getList", this.pageSize);
    // },
    // handleCurrentChange(val) {
    //   console.log(`当前页: ${val}`);
    //   this.currentPage=val
    //   this.$emit("getList",  this.currentPage);
    // }
  },
}
</script>

<style scoped lang="scss">
.pagination-container {
  position: absolute;
  /* background: #fff; */
  /* padding: 32px 16px; */
  bottom: 0;
  z-index: 99;
}
.pagination-container.hidden {
  display: none;
}
.pos_page {
  position: absolute !important;
  right: 0;
  bottom: 1.5rem;
  //   padding-bottom: 20px !important;
  box-sizing: border-box;
  width: 100%;
  height: 64px;
  /* background: transparent; */
  display: flex;
  align-items: center;
}

.new-pagination-style {
  ::v-deep .el-pagination {
    display: flex;
    width: 100%;
    right: 0;
    justify-content: flex-end;
  }

  ::v-deep .el-pagination__total {
    position: absolute;
    left: 16px;
  }
}
</style>
<style>
.el-pagination__sizes .el-input .el-input__inner:hover {
  border-color: #fb8d36 !important;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #fb8d36 !important;
  color: #fff;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #fb8d36;
}
</style>

<template>
  <div class="main">
    <ul>
      <li>
        <span>{{orderNums}}</span>
        <span>累计订单数量</span>
      </li>
      <li style="margin-left:0.416rem">
        <span>{{customerNums}}</span>
        <span>累计服务长者数量</span>
      </li>
      <li style="margin-left:0.416rem">
        <span>{{peopleNums}}</span>
        <span>累计服务人次</span>
      </li>
      <li style="margin-left:0.416rem">
        <span>{{totalAmount}}</span>
        <span>累计订单金额</span>
      </li>
    </ul>
    <div style="flex:1 ;padding-left: 0.416rem;margin-top: 0.5rem;">
      <div
        style="
            font-size: 0.583rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
        "
      >
        今日在线订单显示
      </div>
      <myTable style="margin-top: 10px;"></myTable>
    </div>
  </div>
</template>

<script>
import myTable from './components/table.vue'
import { getContractstaff } from '@/api/elderlyRecords/contract.js'
export default {
    data () {
        return {
            insNo: '',
            totalAmount: '',
      orderNums: '',
      itemNums: '',
      customerNums: '',
      peopleNums: '',
      deviceNums: '',
        }
    },
  components: {
    myTable,
    },
  created() {
    this.insNo=this.$route.query.insNo
    },
  mounted() {
    this.getContractstaff()
    },
  methods: {
    getContractstaff() {
      getContractstaff(this.insNo).then((res) => {
        this.totalAmount = res.data.totalAmount
        this.orderNums = res.data.orderNums
        this.itemNums = res.data.itemNums
        this.customerNums = res.data.customerNums
        this.peopleNums = res.data.peopleNums
        this.deviceNums = res.data.deviceNums
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.main {
  background: url('~@/assets/images/twoThreeImgs/left/orderMessage.png') 0 0
    no-repeat;
  background-size: 100% 100%;
  height: 21.29rem;
  width: 100%;
  overflow: hidden;
  margin-top: 0.41rem;
  display: flex;
  flex-direction: column;
  ul {
    margin-top: 2rem;
    height: 3rem;
    display: flex;
    margin-left: 0.416rem;
    li {
      background: rgba(5, 13, 38, 0.31);
      border-radius: 2px;
      border: 1px solid #213060;
      width: 22.9%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      span {
        &:nth-child(1) {
          font-size: 1.25rem;
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
          color: #ff8529;
          line-height: 35px;
        }
        &:nth-child(2) {
          font-size: 0.583rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #8eb8e6;
        }
      }
    }
  }
}
</style>

<template>
  <div class="main">
    <ul>
      <li>
        <span>{{ staffNums }}</span>
        <span>服务人员数量</span>
      </li>
      <li>
        <span>{{ orderNums }}</span>
        <span>累计服务订单数量</span>
      </li>
      <li>
        <span>{{ itemNums }}</span>
        <span>累计服务项目数量</span>
      </li>
      <li>
        <span>{{ customerNums }}</span>
        <span>累计服务长者数量</span>
      </li>
      <li>
        <span>{{ peopleNums }}</span>
        <span>累计服务人次</span>
      </li>
      <li>
        <span>{{ deviceNums }}</span>
        <span>绑定设备数量</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { getContractstaff } from '@/api/elderlyRecords/contract.js'
export default {
  data() {
    return {
      insNo: '',
      staffNums: '',
      orderNums: '',
      itemNums: '',
      customerNums: '',
      peopleNums: '',
      deviceNums: '',
    }
  },
  created() {
    this.insNo = this.$route.query.insNo
  },
  mounted() {
    this.getContractstaff()
  },
  methods: {
    getContractstaff() {
      getContractstaff(this.insNo).then((res) => {
        this.staffNums = res.data.staffNums
        this.orderNums = res.data.orderNums
        this.itemNums = res.data.itemNums
        this.customerNums = res.data.customerNums
        this.peopleNums = res.data.peopleNums
        this.deviceNums = res.data.deviceNums
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.main {
  background: url('~@/assets/images/twoThreeImgs/left/servicePerson.png') 0 0
    no-repeat;
  background-size: 100% 100%;
  height: 9.44rem;
  width: 100%;
  overflow: hidden;
  ul {
    margin-top: 1.3rem;
    display: flex;
    flex-wrap: wrap;
    li {
      display: flex;
      margin-left: 0.36rem;
      margin-top: 0.41rem;
      width: 7.5rem;
      height: 3.4166666rem;
      background: rgba(5, 13, 38, 0.31);
      border-radius: 2px;
      border: 1px solid #213060;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span {
        &:nth-child(1) {
          font-size: 1.1rem;
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
          color: #b4d3fe;
        }
        &:nth-child(2) {
          font-size: 0.583rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #5e7ca7;
        }
      }
    }
  }
}
</style>

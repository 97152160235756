<template>
  <div class="districtCounty">
    <div class="district">
      <div class="district-header">
        <div class="district-img" @click="back">
          <img src="@/assets/images/twoThreeImgs/return.png" alt="" />
        </div>
        <div class="county-text">
          <div class="county-title" v-if="regionName">{{ regionName }}</div>
          <div class="district-time">
            <div>{{ nowDate }}</div>
            <div>{{ nowWeek }}</div>
            <div>{{ nowTime }}</div>
          </div>
        </div>
      </div>
      <div class="icon-right">
        <warningIcon />
        <div style="cursor: pointer" @click="buttoncli">
          <img
            v-if="isFullscreen"
            src="@/assets/images/top/guanbi.png"
            alt=""
          />
          <img v-else src="@/assets/images/top/dakai.png" alt="" />
        </div>
      </div>
      <div class="header-name" v-if="regionName">
        {{ regionName }}
      </div>
    </div>
    <div class="district-viewmain">
      <left ref="left"></left>
      <middle ref="middle"></middle>
      <right ref="right"></right>
    </div>
  </div>
</template>

<script>
import screenfull from 'screenfull'
import left from './left/index.vue'
import middle from './middle/index.vue'
import right from './right/index.vue'
import warningIcon from '@/components/warningIcon/index.vue'
export default {
  components: {
    left,
    middle,
    right,
    warningIcon,
  },
  data() {
    return {
      //时间
      timer: null,
      nowWeek: '',
      nowDate: '',
      nowTime: '',
      //全屏
      isFullscreen: false,
    }
  },
  computed: {
    regionName() {
      return this.$route.query.titleName
    },
  },
  mounted() {
    //刚加载就启动计时器
    this.timer = setInterval(() => {
      this.setNowTimes()
    }, 1000)
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    // 时间
    setNowTimes() {
      let myDate = new Date()
      let wk = myDate.getDay()
      let yy = String(myDate.getFullYear())
      let mm =
        myDate.getMonth() + 1 < 10
          ? '0' + (myDate.getMonth() + 1)
          : myDate.getMonth() + 1
      let dd = String(
        myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate()
      )
      let hou = String(
        myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours()
      )
      let min = String(
        myDate.getMinutes() < 10
          ? '0' + myDate.getMinutes()
          : myDate.getMinutes()
      )
      let sec = String(
        myDate.getSeconds() < 10
          ? '0' + myDate.getSeconds()
          : myDate.getSeconds()
      )
      let weeks = [
        '星期日',
        '星期一',
        '星期二',
        '星期三',
        '星期四',
        '星期五',
        '星期六',
      ]
      let week = weeks[wk]
      this.nowDate = yy + '-' + mm + '-' + dd
      this.nowTime = hou + ':' + min + ':' + sec
      this.nowWeek = week
    },
    // 全屏
    buttoncli() {
      //   判断浏览器是否支持全屏
      if (!screenfull.isEnabled) {
        this.$message({
          message: '你的浏览器不支持全屏',
          type: 'warning',
        })
        return false
      }
      // 将样式改为锁屏
      if (this.isFullscreen == false) {
        //   打开全屏
        this.isFullscreen = true
        screenfull.toggle()
      } else {
        this.isFullscreen = false
        screenfull.toggle()
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
}
</script>

<style scoped lang="scss">
.districtCounty {
  background: url('~@/assets/images/top/bigbackground3.png') no-repeat 0 0 /
    contain;
  background-size: 100% 100%;
  min-width: 1024px;
  max-width: 1920px;
  min-height: 780px;
  margin: 0 auto;
}
.district {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background: url('~@/assets/images/twoThreeImgs/headerback.png') no-repeat 0 0 /
    contain;
  background-size: 100% 100%;
  width: 100%;
  height: 3rem;
  color: #a6bafd;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  margin-bottom: 0.3rem;
  position: relative;
}
.header-name {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: -0.6rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  font-size: 28px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  background: linear-gradient(180deg, #ffffff 0%, #ffe4ba 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.district-header {
  display: flex;
  align-items: center;
}
.icon-right {
  display: flex;
  align-items: center;
  & > div:first-child {
    margin-right: 0.5rem;
  }
}
.district-img {
  margin-right: 0.4rem;
}
.county-text {
  color: #a6bafd;
}
.district-time {
  display: flex;
}
.district-time > div {
  margin-right: 0.4rem;
}
.county-title {
  font-size: 0.6rem;
  margin-bottom: 0.2rem;
  font-weight: bold;
}

.district-viewmain {
  min-width: 1024px;
  max-width: 1920px;
  min-height: 780px;
  margin: 0 auto;
  display: flex;
  padding: 0 0.4rem 0.7rem;
}
.leftItem {
  width: 40rem;
}
.middleItem {
  margin: 0rem 0.3rem;
}
.rightItem {
  width: 44rem;
}
</style>

<template>
  <div class="main">
    <ul>
      <li>
        <span style="color: #FF8529">{{ num }}</span>
        <span>合同数量</span>
      </li>
      <li>
        <span>
          <span>{{ totalAmount }}</span></span
        >
        <span>合同总金额</span>
      </li>
      <li>
        <span>
          <span>{{ remainingAmount }}</span></span
        >
        <span>合同剩余金额</span>
      </li>
      <li>
        <span>
          <span>{{ amountServed }}</span></span
        >
        <span>服务金额</span>
      </li>
      <li>
        <span style="color: #FF8529">{{ totalNumber }}</span>
        <span>合同总人数</span>
      </li>
      <li>
        <span>{{ peopleServedNumber }}</span>
        <span>服务人数</span>
      </li>
      <li>
        <span>
          <span>{{ peopleServedNumber }}</span></span
        >
        <span>服务人次</span>
      </li>
      <li>
        <span style="color: #25D64D">{{ progressSpeed }}</span>
        <span>总体进度</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { getCostatistics } from '@/api/elderlyRecords/contract.js'
export default {
  data() {
    return {
      insNo: '',
      num: '',
      totalAmount: '',
      remainingAmount: '',
      amountServed: '',
      totalNumber: '',
      peopleServedNumber: '',
      peopleServedNumber: '',
      progressSpeed: '',
    }
  },
  created() {
    this.insNo = this.$route.query.insNo
  },
  mounted() {
    this.getCostatistics()
  },
  methods: {
    getCostatistics() {
      getCostatistics(this.insNo).then((res) => {
        this.num = res.data.num
        this.totalAmount = res.data.totalAmount
        this.remainingAmount = res.data.remainingAmount
        this.amountServed = res.data.amountServed
        this.totalNumber = res.data.totalNumber
        this.peopleServedNumber = res.data.peopleServedNumber
        this.peopleServedNumber = res.data.peopleServedNumber
        this.progressSpeed = res.data.progressSpeed
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  margin-top: 0.41rem;
  overflow: hidden;
  ul {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    li {
      display: flex;
      margin-left: 0.36rem;
      margin-top: 0.41rem;
      width: 6.75rem;
      height: 3.4166666rem;
      background: rgba(5, 13, 38, 0.31);
      box-shadow: inset 0px 0px 13px 0px rgba(18, 42, 255, 0.5);
      border-radius: 2px;
      border: 1px solid #26439e;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span {
        &:nth-child(1) {
          font-size: 1.1rem;
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
          color: #b4d3fe;
        }
        &:nth-child(2) {
          font-size: 0.583rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #5e7ca7;
        }
      }
    }
  }
}
</style>

<template>
  <div class="middleItem">
    <div>
      <areaMap></areaMap>
    </div>
    <div>
      <project></project>
    </div>
  </div>
</template>

<script>
import areaMap from './components/areaMap.vue'
import project from './components/project.vue'
export default {
  components: {
    areaMap,
    project,
  },
}
</script>
<style scoped lang="scss">
.middleItem {
  display: flex;
  flex-direction: column;
  & > div:first-child {
    flex: 1;
  }
  & > div:nth-child(2) {
    margin-top: 0.3rem;
    background: url('~@/assets/images/twoThreeImgs/left/statisticalData.png') 0 0
      no-repeat;
    background-size: 100% 100%;
    height: 11rem;
  }
}
</style>

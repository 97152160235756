<template>
  <div class="main">
    <!-- <div>社区服务前十名(近30天内)</div> -->
    <div class="box">
      <div>
        <div id="distribution" style="width: 100%; height: 100%"></div>
      </div>
      <div>
        <div id="distributiontwo" style="width: 100%; height: 100%"></div>
      </div>
    </div>
  </div>
</template>

<script>
//引入echarts
let echarts = require('echarts')
import { getServiceItems } from '@/api/elderlyRecords/contract.js'
import 'echarts-liquidfill'
export default {
  data() {
    return {
      aggregateMessage: {
        total: 0, //社区敬老卡会员人数
        pointTotal: 0, //积分养老统计总分
        pointCashTotal: 0, //积分养老累计兑换总分
        services: [
          {
            key: '洗脚服务',
            value: 9,
          },
          {
            key: '泡澡五福',
            value: 8,
          },
        ],
      },
    }
  },
  mounted() {
    this.topTen()
  },
  // 计算属性
  computed: {
    insNo() {
      return this.$route.query.insNo
    },
  },
  // 定义函数
  methods: {
    // 颐伦加会员服务内容分布图
      distribution (name, item) {
      this.$nextTick(() => {
        // 基于准备好的dom，初始化echarts实例
        let myChart = echarts.init(document.getElementById('distribution'))
        myChart.setOption({
          grid: {
            left: '-25%',
            right: '5%',
            bottom: '1%',
            top: '5%',
            containLabel: true,
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'none',
            },
            formatter: function(params) {
              return (
                params[0].name +
                '<br/>' +
                "<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:#FF9533'></span>" +
                params[0].seriesName +
                ' : ' +
                Number(
                  (params[0].value.toFixed(4) / 10000).toFixed(2)
                ).toLocaleString() +
                ' 次<br/>'
              )
            },
          },
          //   backgroundColor: "rgb(20,28,52)",
          xAxis: {
            show: false,
            type: 'value',
          },
          yAxis: [
            {
              type: 'category',
              inverse: true,
              axisLabel: {
                // show: true,
                // textStyle: {
                //   color: '#8EB8E6',
                // },
                show: true,
                margin: 150,
                textStyle: {
                  color: '#fff',
                  align: 'left',
                },
                formatter: (params, index) => {
                  return [`{a${index + 1}|  ${index + 1}}  ${params}`].join(
                    '\n'
                  )
                },
                rich: {
                  a1: {
                    color: '#69B1FF',
                    backgroundColor: '#1B2955',
                    width: 13,
                    height: 13,
                    padding: [2, 6, 6, 0],
                    align: 'center',
                    barBorderRadius: 4,
                  },
                  a2: {
                    color: '#69B1FF',
                    backgroundColor: '#1B2955',
                    width: 13,
                    height: 13,
                    padding: [2, 6, 6, 0],
                    align: 'center',
                    barBorderRadius: 4,
                  },
                  a3: {
                    color: '#69B1FF',
                    backgroundColor: '#1B2955',
                    width: 13,
                    height: 13,
                    padding: [2, 6, 6, 0],
                    align: 'center',
                    barBorderRadius: 4,
                  },
                  a4: {
                    color: '#69B1FF',
                    backgroundColor: '#1B2955',
                    width: 13,
                    height: 13,
                    padding: [2, 6, 6, 0],
                    align: 'center',
                    barBorderRadius: 4,
                  },
                  a5: {
                    color: '#69B1FF',
                    backgroundColor: '#1B2955',
                    width: 13,
                    height: 13,
                    padding: [2, 6, 6, 0],
                    align: 'center',
                    barBorderRadius: 4,
                  },
                },
              },
              splitLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              // data: ["清洁", "上门看护", "助浴", "理发", "陪医"],
              data: name,
            },
            {
              type: 'category',
              inverse: true,
              axisTick: 'none',
              axisLine: 'none',
              show: true,
              axisLabel: {
                textStyle: {
                  color: '#8EB8E6',
                  fontSize: '12',
                },
                formatter: function(value) {
                  if (value >= 10000) {
                    return (value / 10000).toLocaleString() + '万'
                  } else {
                    return value.toLocaleString()
                  }
                },
              },
              // data: [2755, 1566, 500, 486],
              data: item,
            },
          ],
          series: [
            {
              name: '次数',
              type: 'bar',
              zlevel: 1,
              itemStyle: {
                normal: {
                  barBorderRadius: 30,
                  color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    {
                      offset: 0,
                      color: '#FFCF43',
                    },
                    {
                      offset: 1,
                      color: '#FF9533',
                    },
                  ]),
                },
              },
              barWidth: 9,
              data: item,
            },
            {
              name: '背景',
              type: 'bar',
              barWidth: 9,
              barGap: '-100%',
              data: item,
              itemStyle: {
                normal: {
                  color: 'rgba(24,31,68,1)',
                  barBorderRadius: 30,
                },
              },
            },
          ],
        })
        window.addEventListener('resize', function() {
          myChart.resize()
        })
      })
    },
      distributiontwo (name, item, max) {
      this.$nextTick(() => {
        // 基于准备好的dom，初始化echarts实例
        let myCharts = echarts.init(document.getElementById('distributiontwo'))
        myCharts.setOption({
          grid: {
            left: '-25%',
            right: '5%',
            bottom: '1%',
            top: '5%',
            containLabel: true,
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'none',
            },
            formatter: function(params) {
              return (
                params[0].name +
                '<br/>' +
                "<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:#FF9533'></span>" +
                params[0].seriesName +
                ' : ' +
                Number(
                  (params[0].value.toFixed(4) / 10000).toFixed(2)
                ).toLocaleString() +
                ' 万元<br/>'
              )
            },
          },
          //   backgroundColor: "rgb(20,28,52)",
          xAxis: {
            show: false,
            type: 'value',
            max: max,
          },
          yAxis: [
            {
              type: 'category',
              inverse: true,
              axisLabel: {
                show: true,
                margin: 150,
                textStyle: {
                  color: '#fff',
                  align: 'left',
                },
                formatter: (params, index) => {
                  return [
                    `{a${index + 1 + 5}|  ${index + 1 + 5}}  ${params}`,
                  ].join('\n')
                },
                rich: {
                  a6: {
                    color: '#69B1FF',
                    backgroundColor: '#1B2955',
                    width: 13,
                    height: 13,
                    padding: [2, 6, 6, 0],
                    align: 'center',
                    barBorderRadius: 4,
                  },
                  a7: {
                    color: '#69B1FF',
                    backgroundColor: '#1B2955',
                    width: 13,
                    height: 13,
                    padding: [2, 6, 6, 0],
                    align: 'center',
                    barBorderRadius: 4,
                  },
                  a8: {
                    color: '#69B1FF',
                    backgroundColor: '#1B2955',
                    width: 13,
                    height: 13,
                    padding: [2, 6, 6, 0],
                    align: 'center',
                    barBorderRadius: 4,
                  },
                  a9: {
                    color: '#69B1FF',
                    backgroundColor: '#1B2955',
                    width: 13,
                    height: 13,
                    padding: [2, 6, 6, 0],
                    align: 'center',
                    barBorderRadius: 4,
                  },
                  a10: {
                    color: '#69B1FF',
                    backgroundColor: '#1B2955',
                    width: 13,
                    height: 13,
                    padding: [2, 7, 6, 0],
                    align: 'center',
                    barBorderRadius: 4,
                  },
                },
              },

              splitLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              // max: 287,
              markPoint: 1,
              // data: ["清洁", "上门看护", "助浴", "理发", "陪医"],
              data: name,
            },
            {
              type: 'category',
              inverse: true,
              axisTick: 'none',
              axisLine: 'none',
              show: true,
              axisLabel: {
                textStyle: {
                  color: '#8EB8E6',
                  fontSize: '12',
                },
                formatter: function(value) {
                  if (value >= 10000) {
                    return (value / 10000).toLocaleString() + '万'
                  } else {
                    return value.toLocaleString()
                  }
                },
              },
              // data: [3678, 2755, 1566, 500, 486],
              data: item,
            },
          ],
          series: [
            {
              name: '金额',
              type: 'bar',
              zlevel: 1,
              itemStyle: {
                normal: {
                  barBorderRadius: 30,
                  color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    {
                      offset: 0,
                      color: '#FFCF43',
                    },
                    {
                      offset: 1,
                      color: '#FF9533',
                    },
                  ]),
                },
              },
              barWidth: 9,
              // data: [3678, 2755, 1566, 500, 486],
              data: item,
            },
            {
              name: '背景',
              type: 'bar',
              barWidth: 9,
              barGap: '-100%',
              // data: [3678, 2755, 1566, 500, 486],
              data: item,
              itemStyle: {
                normal: {
                  color: '#8EB8E6',
                  barBorderRadius: 30,
                },
              },
            },
          ],
        })
        window.addEventListener('resize', function() {
          myCharts.resize()
        })
      })
    },
    topTen() {
      // 在这儿进行接口提交
      getServiceItems(this.insNo)
        .then((res) => {
          if (res.code === 0) {
              this.aggregateMessage = res.data
          } else {
            return
          }
          const copy = this.aggregateMessage
          if (copy.length <= 10) {
            const count = 10 - this.aggregateMessage.length
            for (let i = 0; i < count; i++) {
              copy.push({ serviceName: '暂无数据', serviceNums: 0 })
            }
          }
            const topFiveList = copy.splice(0, 5)
          console.log(topFiveList,22222)
          console.log('topFiveList', topFiveList)
          const topFive = topFiveList.map((item) => {
            const copy = item
            return copy.serviceName
          })
          const topFiveData = topFiveList.map((item) => {
            const copy = item
            return copy.serviceNums
          })
          const lastFiveList = copy
          //   const lastFiveList = copy.splice(5, 10)
          
          const lastFive = lastFiveList.map((item) => {
            const copy = item
            return copy.serviceName
          })
          
          const lastFiveData = lastFiveList.map((item) => {
            const copy = item
            return copy.serviceNums
          })
         
          const max = topFiveData[0]
          // 赋值渲染
            this.flag = true
          console.log(topFive,topFiveData,7)
          this.distribution(topFive, topFiveData)
          this.distributiontwo(lastFive, lastFiveData, max)
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.main {
  background: url('~@/assets/images/twoThreeImgs/left/serviceProject.png') 0 0
    no-repeat;
  background-size: 100% 100%;
  height: 9.4rem;
  margin-top: 0.41rem;
  width: 100%;
  overflow: hidden;
  display: flex;
  .box {
    margin-top: 1rem;
    width: 100%;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    flex: 1;
    display: flex;
    div {
      flex: 1;
    }
  }

   .orderTable {
    &::-webkit-scrollbar {
      width: 0;
    }
    ::v-deep .el-table,
    .el-table tr,
    .el-table td,
    .el-table th {
      background-color: transparent !important;
    }
    overflow: auto;
    flex: 1;
    .el-table {
      ::v-deep td {
        height: 3.08333333rem;
        border: none;
      }
    }
    ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
      background: transparent;
    }
    ::v-deep .el-table td,
    ::v-deep .el-table th.is-leaf {
      border-bottom: 1px solid #1c3269;
    }

    ::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
      background: transparent;
    }
    ::v-deep .el-table__row {
      cursor: pointer;
    }
    ::v-deep .el-table th,
    ::v-deep .el-table tr {
      background: transparent;
      color: #8eb8e6;
    }
  }
}
</style>

<template>
  <!-- 区域地图 -->
  <div class="areaMap">
    <div class="all-institutions">
      <secondaryMap ref="secondaryMap" :region="pathData" />
    </div>
  </div>
</template>
<script>
import { getInstitutionInfo } from '@/api/elderlyRecords/contract.js'
import secondaryMap from '@/components/secondaryMap/index.vue'
export default {
  data() {
    return {}
  },
  components: {
    secondaryMap,
  },
  computed: {
    pathData() {
      let obj = JSON.parse(sessionStorage.getItem('statistics-gov-userInfo'))
      let name_obj=obj.province+obj.area+obj.city
      return name_obj
    },
    insNo() {
      return this.$route.query.insNo
    },
    storeType() {
      return this.$route.query.storeType
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      getInstitutionInfo(this.insNo).then((res) => {
        if (res.code === 0) {
          let dataData = res.data
          let arr = [
            {
              insNo: dataData.institutionNo,
              name: dataData.institutionName,
              area: dataData.operatingArea,
              spName: dataData.supplierName,
              percent: dataData.percent,
              lng: dataData.lng,
              lat: dataData.lat,
              storeType: this.storeType,
            },
          ]
          this.$refs.secondaryMap.initializeMap(arr)
        }
      })
    },
  },
}
</script>
<style scoped lang="scss">
.areaMap {
  width: 100%;
  height: 100%;
  .all-institutions {
    background: url('~@/assets/images/top/mapback.png') 0 0 no-repeat;
    background-size: 100% 100%;
    margin-bottom: 0.3rem;
    width: 100%;
    height: 100%;
  }
}
</style>

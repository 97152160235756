<template>
  <myTable
    :tableData="tableData"
    :columns="columns"
    :handleSizeChange="handleSizeChange"
    :handleCurrentChange="handleCurrentChange"
    :pageObj="pageObj"
    :border="false"
    :tableMaxHeight="680"
  >
  </myTable>
</template>

<script>
import myTable from '@/components/table/table.vue'
import { getContrapage } from '@/api/elderlyRecords/contract.js'
export default {
  components: {
    myTable,
  },
  data() {
    return {
      tableData: [],
      pageObj: {
        totalCount: 0,
        totalPage: 0,
        pagesize: 10,
        currentPage: 1,
      },
      columns: [
        {
          label: '时间',
          prop: 'createTime',
        },
        {
          label: '订单编号',
          prop: 'orderNo',
        },
        {
          label: '服务项目',
          prop: 'itemsName',
        },
        {
          label: '状态',
          prop: 'statusMark',
        },
      ],
      insNo: '',
    }
  },
  created() {
    this.insNo = this.$route.query.insNo
  },
  mounted() {
    this.init()
  },
  methods: {
    handleSizeChange(val) {
      this.pageObj.pagesize = val
      this.init()
    },
    handleCurrentChange(val) {
      this.pageObj.currentPage = val
      this.init()
    },
    init() {
      let obj = {
        institutionNo: this.insNo,
        perPage: this.pageObj.pagesize,
        page: this.pageObj.currentPage,
      }
      getContrapage(obj).then((res) => {
        this.pageObj.totalCount = res.total
        this.tableData = res.data
        this.tableData.forEach((item) => {
          item.itemsName = ''
        })
        this.tableData.forEach((item) => {
          if (item.items && item.items.length > 1) {
            item.items.forEach((i) => {
              item.itemsName = item.itemsName + i.serviceName + ','
            })
          } else if (item.items) {
            item.items.forEach((i) => {
              item.itemsName = i.serviceName
            })
          }
        })
      })
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .el-table,
.el-table tr,
.el-table td,
.el-table th {
  background-color: transparent !important;
}
::v-deep .el-input__inner {
  background: rgba(7, 18, 85, 0.5);
  box-shadow: inset 0px 0px 8px 0px rgba(0, 180, 255, 0.47);
  border-radius: 2px;
  border: 1px solid #104b8b;
  color: #8eb8e6;
}
::v-deep .el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: rgba(7, 18, 85, 0.5);
  box-shadow: inset 0px 0px 8px 0px rgba(0, 180, 255, 0.47);
  border-radius: 2px;
  border: 1px solid #104b8b;
}
::v-deep .el-pagination.is-background .btn-prev {
  background: rgba(7, 18, 85, 0.5);
  box-shadow: inset 0px 0px 8px 0px rgba(0, 180, 255, 0.47);
  border-radius: 2px;
  border: 1px solid #104b8b;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #312316 !important;
  border: 1px solid #ed7301;
  color: #ed7301;
}
::v-deep .el-pagination__total {
  margin-right: 10px;
  font-weight: 400;
  color: #8eb8e6;
}
.el-table {
  ::v-deep td {
    height: 3.08333333rem;
    border: none;
  }
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: transparent;
}

::v-deep .el-table__header-wrapper {
  background: transparent;
  .el-table__header {
    background: transparent;
  }
}
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: transparent;
}
::v-deep .el-table__row {
  cursor: pointer;
}
::v-deep .el-table th,
::v-deep .el-table tr {
  background: transparent;
  color: #8eb8e6;
}
</style>

<template>
  <div class="data-table">
    <el-table
      ref="myTable"
      :data="tableData"
      style="width: 100%"
      :border="border"
      :size="tableSize"
      :header-cell-style="tableHeader"
      :highlight-current-row="currentRowFal"
      :default-expand-all="expandAll"
      @row-click="getCurrentRow"
      :row-key="rowKey || ''"
      :tree-props="treeProps"
      v-loading="loading"
      :height="thisTableMaxHeight || height"
      @selection-change="handleSelectionChange"
      :span-method="objectSpanMethod"
      :row-class-name="tableRowClassName"
      :cell-style="cellStyle"
      @cell-mouse-enter="handleCellMouseEnter"
      @cell-mouse-leave="handleCellMouseLeave"
    >
      <el-table-column width="55" v-if="type == 'radio'">
        <template slot-scope="scope">
          <el-radio
            v-model="radio"
            :label="scope.row.id"
            @change.native="getCurrentRow(scope.row)"
            ><span>&nbsp;</span></el-radio
          >
        </template>
      </el-table-column>
      <el-table-column
        type="selection"
        :reserve-selection="reserveSelection"
        width="55"
        v-if="type == 'checkbox'"
      >
      </el-table-column>

      <el-table-column
        label="序号"
        width="80"
        align="center"
        v-if="indexfal !== false"
      >
        <template slot-scope="scope">
          <!-- 有分页时，序号显示 -->
          <span v-if="pageObjs">{{
            (pageObjs.currentPage - 1) * pageObjs.pagesize + scope.$index + 1
          }}</span>
          <!-- 无分页时，序号显示 -->
          <span v-else>{{ scope.$index + 1 }}</span>
        </template>
      </el-table-column>

      <template v-for="(col, index) in columns">
        <!-- 操作列/自定义列 -->
        <slot v-if="col.slot" :name="col.slot"></slot>
        <!-- formatter 数据过滤 -->
        <el-table-column
          v-else-if="col.formatter"
          :key="index"
          :prop="col.template"
          :label="col.label"
          :width="col.width"
          :formatter="col.formatter"
          :show-overflow-tooltip="true"
          :align="col.align || 'center'"
        >
        </el-table-column>

        <el-table-column
          v-else
          :prop="col.prop"
          :label="col.label"
          :width="col.width"
          :formatter="col.formatter"
          :align="col.align || 'center'"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <!-- 点击跳转页面 -->
            <div v-if="col.router" style="color: red">
              <router-link
                :to="{
                  path: col.router.path,
                  query: { expertFields: scope.row['fieldName'] },
                }"
                >{{ scope.row[col.prop] }}</router-link
              >
            </div>
            <!-- 过滤器 公共属性 -->
            <div v-else-if="col.formatData">
              {{ scope.row[col.prop] | formatters(col.formatData) }}
            </div>
            <!-- 纯展示数据 -->
            <template v-else>{{ scope.row[col.prop] || '' }}</template>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <!-- 是否调用分页 -->
    <Pagination
      v-if="pageObjs"
      :currentPage="pageObjs.currentPage"
      :pageSize="pageObjs.pagesize"
      :total="pageObjs.totalCount"
      :handleSizeChange="handleSizeChange"
      :handleCurrentChange="handleCurrentChange"
      :pageSizes="pageObjs.pageSizes"
    ></Pagination>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/index.vue'
export default {
  components: {
    Pagination,
  },
  props: [
    'tableData',
    'columns', //列
    'loading', //加载中
    'pageObj', //分页
    'tableSize', //tablesize
    'handleCurrentChange', //分页
    'handleSizeChange', //分页
    'type', //表格类型  checkbox 复选框 radio 单选框
    'currentRowFal', // 表格行是否可选中
    'align', //居中
    'indexfal', //序号显示
    'border', //表格样式
    'treeProps',
    'rowKey', //树型table 需要参数
    'expandAll', //展开全部
    'reserveSelection', //  多选记住选中状态
    'height', // 表格高度
    'tableMaxHeight', //自适应表格高度
    'objectSpanMethod', //合并表格方法
    'handleCellMouseLeave',
    'handleCellMouseEnter',
    'tableRowClassName',
    'cellStyle',
  ],
  watch: {
    pageObj: {
      handler(newVal) {
        this.pageObjs = newVal
      },
      immediate: true,
      deep: true,
    },
    tableMaxHeight: {
      handler() {
        if (this.tableMaxHeight) {
          this.thisTableMaxHeight =
            window.innerHeight - this.tableMaxHeight + 'px'
        }
      },
      immediate: true,
    },
  },
  mounted: function() {
    this.radio = ''
    this.checked = ''
  },
  filters: {
    formatters(val, format) {
      if (typeof format === 'function') {
        return format(val)
      } else return val
    },
  },
  data() {
    return {
      radio: 0,
      checked: [],
      pageObjs: {},
      thisTableMaxHeight: null,
    }
  },
  methods: {
    tableHeader({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #152452;color: #8EB8E6;font-weight: bold;'
      }
    },
    handleSelectionChange(val) {
      this.$emit('getCurrentRow', val)
    },
    // 点击行
    getCurrentRow(row) {
      if (row == null) {
        return
      }
      if (this.type == 'radio') {
        this.radio = parseInt(row.id)
      } else if (this.type == 'checkbox') {
        let thisdata = this.$refs.myTable.selection
        // this.$refs.myTable.toggleRowSelection(row);
        this.$emit('getCurrentRow', thisdata)
      } else {
        this.$emit('getCurrentRow', row)
      }
    },
    tableMaxHeights() {
      this.thisTableMaxHeight = window.innerHeight - this.tableMaxHeight + 'px'
    },
  },
  created() {
    window.addEventListener('resize', this.tableMaxHeights)
  },
  destroyed() {
    window.removeEventListener('resize', this.tableMaxHeights)
  },
}
</script>
<style lang="scss">
::v-deep .el-radio {
  color: rgba(247, 243, 243, 0) !important;
}
.data-table {
  padding-bottom: 64px;
  transform: translateZ(0);
  height: 14rem;
  position: relative;
}
::v-deep .el-dropdown-menu__item {
  padding: 0 !important;
}
::v-deep .el-dropdown-menu__item > div > span {
  display: inline-block;
  width: 100%;
}
::v-deep .el-dropdown-menu__item > div > span > button {
  width: 100%;
}
::v-deep .el-dropdown-menu__item > div > a {
  display: inline-block;
  width: 100%;
  text-align: center;
}
::v-deep .el-table__body {
  width: 100% !important;
}
::v-deep .el-table__header colgroup col[name='gutter'] {
  width: 0px !important;
}
::v-deep .el-table__row {
  height: 54px;
}

::v-deep .el-table {
  th.gutter,
  colgroup.gutter {
    width: 17px !important;
  }
}

::v-deep .el-table__header colgroup col[name='gutter'] {
  display: table-cell !important;
}

::v-deep .el-table__body {
  width: 100% !important;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  content: '';
  position: absolute;
  background-color: #051039;
  z-index: 1;
}
.el-table td,
.el-table th.is-leaf {
  border-bottom: 1px solid #051039;
}
</style>

import request from '@/utils/request'

//  根据机构号查询合同
export const getContractByIns = (data) =>
  request({
    url:
      '/hp/platform/data/v1/screen/gov/contract/list/institutionNo?institutionNo=' +
      data,
    method: 'get',
  })

// 服务项目信息
export const getServiceItems = (data) =>
  request({
    url:
      '/hp/platform/data/v1/screen/gov/item/statistics?institutionNo=' + data,
    method: 'get',
  })
//  服务人员信息
export const getContractstaff = (data) =>
  request({
    url:
      '/hp/platform/data/v1/screen/gov/statistics/staff?institutionNo=' + data,
    method: 'get',
  })
//  订单分页
export const getContrapage = (data) =>
  request({
    url: '/hp/platform/data/v1/screen/gov/today/order/page',
    method: 'post',
    data,
  })
//  合同总数居
export const getCostatistics = (data) =>
  request({
    url:
      '/hp/platform/data/v1/screen/gov/contract/statistics/institutionNo?institutionNo=' +
      data,
    method: 'get',
  })
//  非退款老人人数
export const getCostatinums = (data) =>
  request({
    url:
      '/hp/platform/data/v1/screen/gov/reports/serviced-nums?contractGlobalId=' +
      data,
    method: 'get',
  })
//获取机构信息
export const getInstitutionInfo = (insNo) =>
  request({
    url: '/hp/platform/data/v1/screen/ins/institutionInfo?insNo=' + insNo,
    method: 'get',
  })
// 统计数据集合-二级页面区域内人口数据
export const getStatistics = (code) =>
  request({
    url: '/hp/platform/data/v1/screen/mock-data?level=3',
    method: 'get',
    params: {
      districtCode: code,
    },
  })
